<template>
  <div>
    <a-drawer title='修改级别' :width='720' v-if="visible" :visible='visible' :body-style="{ paddingBottom: '80px' }"
      @close='onClose'>
      <a-form-model ref="ruleForm" :model='form' :rules="rules" :label-col='{ span: 4 }' :wrapper-col='{ span: 8 }'>
        <a-form-model-item prop='title' label='级别名称' class='margin-bottom-sm'>
          <a-input v-model='form.title' placeholder='请输入级别名称' allow-clear />
        </a-form-model-item>
        <a-form-model-item prop="description" label='级别描述' class='margin-bottom-sm' :label-col='{ span: 4 }'
          :wrapper-col='{ span: 20 }'>
          <a-textarea name="description" v-model='form.description' placeholder='请输入级别描述'
            :auto-size='{ minRows: 3, maxRows: 5 }' />
        </a-form-model-item>
        <a-form-model-item prop="mark" label='级别标识' class='margin-bottom-0'>
          <div class="text-sm">图片建议尺寸：30*30像素</div>
          <a-upload name="mark" list-type="picture-card" class="avatar-uploader" :show-upload-list="false" action=""
            :beforeUpload="beforeUpload" @change="uploadImg($event, 1)">
            <img v-if="form.mark" :src="form.mark" alt="avatar" />
            <div v-else>
              <a-icon :type="loading ? 'loading' : 'plus'" />
              <div class="ant-upload-text">
                上传标识图片
              </div>
            </div>
          </a-upload>
        </a-form-model-item>
        <a-form-item prop="background" label='级别背景' class='margin-bottom-0'>
          <div class="text-sm">图片建议尺寸：665x325像素</div>
          <a-upload name="background" list-type="picture-card" class="avatar-uploader" :show-upload-list="false" action=""
            :beforeUpload="beforeUploadbg" @change="uploadImg($event, 2)">
            <img v-if="form.background" :src="form.background" alt="avatar" />
            <div v-else>
              <a-icon :type="loading ? 'loading' : 'plus'" />
              <div class="ant-upload-text">
                上传级别背景图片
              </div>
            </div>
          </a-upload>
        </a-form-item>
        <a-form-item prop='standardType' label='达标选择方式' :label-col='{ span: 4 }' :wrapper-col='{ span: 18 }'
          class='margin-bottom-sm'>
          <a-radio-group name='standardType' v-model='form.standardType' @change='onChange_standardType'
            button-style="solid">
            <a-radio-button v-for="(d, index) in standardTypeOption" :key="index" :value="parseInt(d.value)">{{ d.label
            }}</a-radio-button>
          </a-radio-group>
        </a-form-item>
        <a-card v-show="form.standardType === 1 || form.standardType === 2">
          <div class="">
            <div class="margin-bottom-sm">
              <a-checkbox @change='checkboxChange($event, 0)' :disabled="conditionCheckedArr[0].isDisable"
                :checked="conditionCheckedArr[0].ischecked" value="1">
                <span style="color:rgba(0, 0, 0, 0.85)">消费额</span>
              </a-checkbox>
            </div>
            <div class="margin-left">
              <a-card>
                <a-form-item label="消费类型" class="margin-bottom-0" :label-col='{ span: 4 }' :wrapper-col='{ span: 20 }'>
                  <a-radio-group :disabled="conditionCheckedArr[0].isSonDiasble" name='typeValue'
                    v-model='formCondition[0].typeValue' @change='onChange($event, 1)'>
                    <a-radio :value="1" key="countCost">商城总消费</a-radio>
                    <a-radio :value="2" key="oneCost">一次性消费</a-radio>
                  </a-radio-group>
                </a-form-item>
                <a-form-item label="消费金额" class="margin-bottom-0" :label-col='{ span: 4 }' :wrapper-col='{ span: 20 }'>
                  <a-input :disabled="conditionCheckedArr[0].isSonDiasble" name="moneyValue"
                    v-model='formCondition[0].moneyValue' style="width:80px" />元
                  <span class="text-sm padding-left-xs">(注：用户需消费此额度才能成为该级别分销商)</span>
                </a-form-item>
                <a-form-item label="生效状态" class="margin-bottom-0" :label-col='{ span: 4 }' :wrapper-col='{ span: 20 }'>
                  <a-radio-group :disabled="conditionCheckedArr[0].isSonDiasble" name='activeValue'
                    v-model='formCondition[0].activeValue' @change='onChange($event, 2)'>
                    <a-radio :value="1" key="orderPay">订单付款后计入</a-radio>
                    <a-radio :value="2" key="orderReceive">订单确认收货后计入</a-radio>
                  </a-radio-group>
                </a-form-item>
              </a-card>
            </div>
          </div>
          <!-- 二 购买礼包 -->
          <div class="flex align-center margin-bottom-sm">
            <div>
              <a-checkbox @change='checkboxChange($event, 1)' value="2" :checked="conditionCheckedArr[1].ischecked"
                :disabled="conditionCheckedArr[1].isDisable">
              </a-checkbox>
            </div>
            <div class="flex-sub">
              <a-form-item label="购买礼包" class="margin-bottom-0" :label-col='{ span: 4 }' :wrapper-col='{ span: 14 }'>
                <a-radio-group :disabled="conditionCheckedArr[1].isSonDiasble" name='typeValue'
                  v-model='formCondition[1].typeValue' @change='onChange'>
                  <a-radio :value="1" key="countCost">任意商品</a-radio>
                  <a-radio :value="2" key="oneCost">特定商品</a-radio>
                </a-radio-group>
              </a-form-item>
            </div>
          </div>
          <!-- 三 购买商品次数 -->
          <div class="flex align-center margin-bottom-sm">
            <div class="padding-right-xs">
              <a-checkbox :disabled="conditionCheckedArr[2].isDisable" :checked="conditionCheckedArr[2].ischecked"
                @change='checkboxChange($event, 2)' value="3">
              </a-checkbox>
            </div>
            <div class="flex-sub">
              <a-form-item label="购买商品次数" class="margin-bottom-0" :label-col='{ span: 4 }' :wrapper-col='{ span: 20 }'>
                <a-input :disabled="conditionCheckedArr[2].isSonDiasble" name="buyNumValue"
                  v-model='formCondition[2].buyNumValue' style="width:80px" /><span class="padding-left-xs">次</span>
                <span class="text-sm">(注：用户需消费此额度才能成为该级别分销商)</span>
              </a-form-item>
            </div>
          </div>
          <!-- 四 团队销售额 -->
          <div class="flex align-center margin-bottom-sm">
            <div><a-checkbox :checked="conditionCheckedArr[3].ischecked" @change='checkboxChange($event, 3)' value="4"
                :disabled="conditionCheckedArr[3].isDisable"></a-checkbox></div>
            <div class="flex-sub">
              <a-form-item label="团队销售额" class="margin-bottom-0" :label-col='{ span: 4 }' :wrapper-col='{ span: 20 }'>
                <a-input :disabled="conditionCheckedArr[3].isSonDiasble" name="teamSalesValue"
                  v-model='formCondition[3].teamSalesValue' style="width:80px" /><span class="padding-left-xs">元</span>
              </a-form-item>
            </div>
          </div>
          <!-- 五 直接购买身份 -->
          <div class="flex align-center margin-bottom-sm">
            <div class="padding-right-xs"><a-checkbox :checked="conditionCheckedArr[4].ischecked"
                @change='checkboxChange($event, 4)' value="5" :disabled="conditionCheckedArr[4].isDisable"></a-checkbox>
            </div>
            <div class="flex-sub">
              <a-form-item label="直接购买身份" class="margin-bottom-0" :label-col='{ span: 4 }' :wrapper-col='{ span: 20 }'>
                <div class="flex align-center">
                  <a-select :disabled="conditionCheckedArr[4].isSonDiasble" name='configValue'
                    v-model='formCondition[4].configValue' style="width: 120px" @change="onChange">
                    <a-select-option key='onlyBuy' :value='1'>直接购买</a-select-option>
                    <a-select-option key='giveGoods' :value='2'>送产品</a-select-option>
                    <a-select-option key='addBalance' :value='3'>存入余额</a-select-option>
                  </a-select>
                  <div class="flex-sub margin-left-sm flex align-center">
                    <div class="flex align-center">
                      <a-input :disabled="conditionCheckedArr[4].isSonDiasble" name='moneyValue'
                        v-model="formCondition[4].moneyValue" style="width:80px" />
                      <span class="padding-left-xs">元</span>
                    </div>
                    <div class="padding-left-sm">
                      <div v-if="formCondition[4].configValue == 3" class="flex">
                        <div class="padding-right-xs">赠送金额</div>
                        <div>
                          <a-input :disabled="conditionCheckedArr[4].isSonDiasble" name='moneyValue'
                            v-model="formCondition[4].freeMoney" style="width:80px" />
                          <span class="padding-left-xs">元</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </a-form-item>
            </div>
          </div>
          <!-- 六 直邀等级数量 -->
          <div class="flex align-center margin-bottom-sm">
            <div class="padding-right-xs"><a-checkbox :checked="conditionCheckedArr[5].ischecked"
                @change='checkboxChange($event, 5)' value="6" :disabled="conditionCheckedArr[5].isDisable"></a-checkbox>
            </div>
            <div class="flex-sub">
              <a-form-item v-for="(item, index) in formCondition[5]" :key="index" label="直邀等级数量" class="margin-bottom-0"
                :label-col='{ span: 4 }' :wrapper-col='{ span: 20 }'>
                <div class="flex">
                  <div class="flex align-center">
                    <a-input :disabled="conditionCheckedArr[5].isSonDiasble" name='peopleValue' v-model="item.peopleValue"
                      style="width:80px" />
                    <span class="padding-left-xs">人</span>
                  </div>
                  <a-select :disabled="conditionCheckedArr[5].isSonDiasble" name='configValue' v-if="levelList.length"
                    v-model="item.levelValue" @change="changeLevel($event, 5, index)" style="width: 120px"
                    placeholder="请选择分销等级" class="margin-left-sm">
                    <a-select-option v-for="(item2, index2) in levelList" :key='item2.level' :value='item2.level'>
                      {{ item2.title }}
                    </a-select-option>
                  </a-select>
                  <a @click="addLevelNum(5, index)" v-if='index == 0' href="javascript:void(0)" class="padding-left-xs"
                    style="width: 50px;display:inline-block;">添加</a>
                  <a @click="delLevelNum(5, index)" v-if='index !== 0' href="javascript:void(0)"
                    class="padding-left-xs text-red" style="width: 50px;display:inline-block;">删除</a>
                </div>
              </a-form-item>
            </div>
          </div>
          <!-- 七 团队等级数量 -->
          <div class="flex align-center margin-bottom-sm">
            <div class="padding-right-xs"><a-checkbox :checked="conditionCheckedArr[6].ischecked"
                @change='checkboxChange($event, 6)' value="7" :disabled="conditionCheckedArr[6].isDisable"></a-checkbox>
            </div>
            <div class="flex-sub">
              <a-form-item v-for="(item, index) in formCondition[6]" :key="index" label="团队等级数量" class="margin-bottom-0"
                :label-col='{ span: 4 }' :wrapper-col='{ span: 20 }'>
                <div class="flex">
                  <div class="flex align-center">
                    <a-input :disabled="conditionCheckedArr[6].isSonDiasble" name='peopleValue' v-model="item.peopleValue"
                      style="width:80px" />
                    <span class="padding-left-xs">人</span>
                  </div>
                  <a-select @change="changeLevel($event, 6, index)" :disabled="conditionCheckedArr[6].isSonDiasble"
                    name='levelValue' v-model='item.levelValue' style="width: 120px" placeholder="请选择分销等级"
                    class="margin-left-sm">
                    <a-select-option v-for="(item2, index2) in levelList" :key='item2.level' :value='item2.level'>
                      {{ item2.title }}
                    </a-select-option>
                  </a-select>
                  <a @click="addLevelNum(6, index)" v-if='index === 0' href="javascript:void(0)" class="padding-left-xs"
                    style="width: 50px;display:inline-block;">添加</a>
                  <a @click="delLevelNum(6, index)" v-if='index !== 0' href="javascript:void(0)"
                    class="padding-left-xs text-red" style="width: 50px;display:inline-block;">删除</a>
                </div>
              </a-form-item>
            </div>
          </div>
        </a-card>
        <div v-show="form.standardType === 4">
          <a-table :columns="columns" :data-source="data" :pagination="false" rowKey="id">
            <div slot="fieldtype" slot-scope="text, record, index">
              <a-select style="width: 120px" @change="changeFieldtype($event, record)" v-model="text"
                :options="fieldtypeArr">
              </a-select>
            </div>
            <span slot="fieldname" slot-scope="text, record">
              <a-input v-model="text" required @change="e => handleChange(e.target.value, record.id, 'fieldname')" />
            </span>
            <span slot="initialcontent" slot-scope="text, record">
              <a-input v-model="text" required @change="e => handleChange(e.target.value, record.id, 'initialcontent')" />
            </span>
            <template slot="operation" slot-scope="text, record, index">
              <div class="editable-row-operations">
                <a-popconfirm title="确定要删除吗？" ok-text="确定" cancel-text="取消" @confirm="delFields(record)">
                  <a href="javascript:void(0)" class="text-red">删除</a>
                </a-popconfirm>
              </div>
            </template>
          </a-table>
          <div>
            <a-button type="primary" @click="addCondition">
              添加条件
            </a-button>
          </div>
        </div>
        <div class='foot-btn'>
          <a-button :style="{ marginRight: '8px' }" @click='onClose'>
            取消
          </a-button>
          <a-button type='primary' @click="submit" :loading="isloading" :disabled="isloading">
            确定
          </a-button>
        </div>
      </a-form-model>
    </a-drawer>
  </div>
</template>
<script>
const columns = [
  {
    title: '字段类型',
    dataIndex: 'fieldtype',
    width: '25%',
    scopedSlots: { customRender: 'fieldtype' },
  },
  {
    title: '字段名称',
    dataIndex: 'fieldname',
    width: '30%',
    scopedSlots: { customRender: 'fieldname' },
  },
  {
    title: '初始内容',
    dataIndex: 'initialcontent',
    width: '30%',
    scopedSlots: { customRender: 'initialcontent' },
  },
  {
    title: '操作',
    dataIndex: 'operation',
    scopedSlots: { customRender: 'operation' },
  },
];

import * as distribution from '@/api/distribution'
import { guid } from '@/utils/ruoyi.js'

export default {
  data() {
    return {
      isloading: false,
      data: [
        {
          id: guid(8, 10),
          fieldtype: '1',
          fieldname: '',
          initialcontent: ''
        }
      ],
      columns,
      'fieldtypeArr': [{
        'key': 'input',
        'label': '文本框',
        'value': '1'
      }, {
        'key': 'select',
        'label': '选择框',
        'value': '2'
      }, {
        'key': 'areaselect',
        'label': '地区选择',
        'value': '3'
      }, {
        'key': 'img',
        'label': '图片',
        'value': '4'
      }],
      editingKey: '',
      standardTypeOption: [{
        label: '满足任一条件',
        value: 1
      }, {
        label: '满足全部条件',
        value: 2
      }, {
        label: '不限制',
        value: 3
      }, {
        label: '手动申请',
        value: 4
      }],
      // form: this.$form.createForm(this),
      loading: false,
      visible: false,
      limit_value: 1,
      form: {
        id: '',
        title: '',
        description: '',
        mark: '',
        background: '',
        standardType: 1,
        levelConditions: []
      },
      rules: {
        title: [
          { required: true, message: '请输入级别名称' }
        ],
      },
      conditionCheckedArr: [
        {
          ischecked: false,
          isDisable: false,
          isSonDiasble: true
        }, {
          ischecked: false,
          isfaDisable: false,
          isSonDiasble: true
        }, {
          ischecked: false,
          isfaDisable: false,
          isSonDiasble: true
        }, {
          ischecked: false,
          isfaDisable: false,
          isSonDiasble: true
        }, {
          ischecked: false,
          isfaDisable: false,
          isSonDiasble: true
        }, {
          ischecked: false,
          isfaDisable: false,
          isSonDiasble: true
        }, {
          ischecked: false,
          isfaDisable: false,
          isSonDiasble: true
        }
      ],
      formCondition: [
        {
          "typeValue": 1,
          "moneyValue": '',
          "activeValue": 1
        },
        {
          "typeValue": 1,
          "goodsIds": "",
          "activeValue": ''
        },
        { buyNumValue: '' },
        { teamSalesValue: '' },
        {
          "configValue": 1,
          "moneyValue": '',
          "goodsIds": "",
          "freeMoney": ""
        },
        [{
          "levelValue": '',
          "peopleValue": ''
        }],
        [{
          "levelValue": '',
          "peopleValue": ''
        }]
      ],
      levelList: [],

    }
  },
  filters: {
    standarName(type) {
      let str = ''
      switch (type) {
        case 1: str = '满足任一条件';
          break;
        case 2: str = '满足全部条件';
          break;
        case 3: str = '不限制';
          break;
        case 4: str = '手动申请';
          break;
      }
      return str
    }
  },
  mounted() {

  },
  methods: {
    handleChange(value, id, column) {
      const newData = [...this.data];
      const target = newData.find(item => id === item.id);
      if (target) {
        target[column] = value;
        this.data = newData;
      }
    },
    addCondition() {
      this.data.push({
        id: guid(8, 10),
        fieldtype: '1',
        fieldname: '',
        initialcontent: ''
      })
    },
    changeFieldtype(e, record) {
      let value = e
      this.data.map((item, index) => {
        if (item.id == record.id) {
          this.$set(this.data[index], 'fieldtype', value)
        }
      })
      this.$forceUpdate()
    },
    delFields(info) {
      let delIndex = this.data.findIndex(item => { return info.id == item.id })
      this.data.splice(delIndex, 1)
    },
    edit(key) {
      const newData = [...this.data];
      const target = newData.find(item => key === item.key);
      this.editingKey = key;
      if (target) {
        target.editable = true;
        this.data = newData;
      }
    },
    showDrawer() {
      this.data = []
      this.visible = true
    },
    onClose() {
      this.data = []
      this.visible = false
    },
    /**
     * 获取等级详细信息
     */
    getInfo(info) {
      let request1 = distribution.getLevelList()
      let request2 = distribution.getLevelInfo({ id: info.id })
      Promise.all([request1, request2]).then((res) => {
        if (res[0].success) {
          this.levelList = res[0].data
        }
        if (res[1].success) {
          this.form = res[1].data
          if (res[1].data.levelConditions && res[1].data.levelConditions.length) {
            this.conditionCheckedArr.map((item, index) => {
              res[1].data.levelConditions.map((item1, index1) => {
                if (item1.conditionType == index + 1) {
                  item.id = item1.id
                  this.$set(this.conditionCheckedArr[index], 'ischecked', true)
                  this.$set(this.conditionCheckedArr[index], 'isSonDiasble', false)
                }
              })
            })
            // 如果选中的有第四个，其他要置灰
            let objImme = res[1].data.levelConditions.find((item) => { return item.conditionType === 5 })
            if (objImme && objImme.id) {
              this.conditionCheckedArr.map((item, index) => {
                if (index !== 4) {
                  this.$set(this.conditionCheckedArr[index], 'ischecked', false)
                  this.$set(this.conditionCheckedArr[index], 'isDisable', true)
                  this.$set(this.conditionCheckedArr[index], 'isSonDiasble', true)
                } else {
                  this.$set(this.conditionCheckedArr[4], 'ischecked', true)
                  this.$set(this.conditionCheckedArr[index], 'isDisable', false)
                  this.$set(this.conditionCheckedArr[4], 'isSonDiasble', false)
                }
              })
            }
            this.formCondition.map((item, index) => {
              res[1].data.levelConditions.map((item1, index1) => {
                if (item1.conditionType == index + 1) {
                  let obj = JSON.parse(item1.conditionValueJson)
                  if (index === 0) {
                    item.typeValue = obj.typeValue
                    item.moneyValue = obj.moneyValue
                    item.activeValue = obj.activeValue
                  } else if (index === 1) {
                    item.typeValue = obj.typeValue
                    item.goodsIds = obj.goodsIds
                    item.activeValue = obj.activeValue
                  } else if (index == 2) {
                    item.buyNumValue = obj.buyNumValue
                  } else if (index === 3) {
                    item.teamSalesValue = obj.teamSalesValue
                  } else if (index === 4) {
                    item.configValue = obj.configValue
                    item.moneyValue = obj.moneyValue
                    item.goodsIds = obj.goodsIds
                  } else if (index === 5 || index === 6) {
                    this.formCondition[index] = obj
                  }
                }
              })
            })
          }
          if (res[1].data.standardType == 4 && res[1].data.applyConfig) {
            this.data = []
            this.data = JSON.parse(res[1].data.applyConfig)
          }
        }
      }).catch((error) => {
        console.log(error)
      })
    },
    getLevelList() {
      distribution.getLevelList().then(res => {
        if (res.success) {
          this.levelList = res.data
        }
      })
    },
    /**
     * 选择达标方式
     */
    onChange_standardType(e) {
      this.form.standardType = e.target.value
    },
    onChange() { },
    changeLevel(e, index, index2) {
      this.$set(this.formCondition[index][index2], 'levelValue', e)
      this.$forceUpdate()
    },
    addLevelNum(index) {
      let obj = {
        "levelValue": '',
        "peopleValue": ''
      }
      this.formCondition[index].push(obj)
      this.$forceUpdate()
    },
    delLevelNum(index, index2) {
      this.formCondition[index].splice(index2, 1)
      this.$forceUpdate()
    },
    /**
     * 直接购买身份
     *
     */
    checkboxChange(e, index) {
      let checked = e.target.checked
      this.conditionCheckedArr[index].ischecked = checked
      if (index === 4) {
        // 直接购买身份 与其他6个条件互斥，选择完当前项，其他6项都不可勾选
        if (checked) {
          this.conditionCheckedArr[4].isDisable = false
          this.conditionCheckedArr[index].isSonDiasble = false
          this.conditionCheckedArr.forEach((item, index) => {
            if (index !== 4) {
              this.conditionCheckedArr[index].isDisable = true
              this.conditionCheckedArr[index].isSonDiasble = true
            }
          })
        } else {
          this.conditionCheckedArr[4].isDisable = false
          this.conditionCheckedArr[index].isSonDiasble = true
          this.conditionCheckedArr.forEach((item, index) => {
            if (index !== 4) {
              this.conditionCheckedArr[index].isDisable = false
              this.conditionCheckedArr[index].isSonDiasble = false
            }
          })
        }
      } else {
        // 不勾选当前模块的表单不可操作，勾选后可操作性
        this.conditionCheckedArr[index].isSonDiasble = !checked
      }
      this.$forceUpdate()
    },
    setCheckboxFn(onOff) {
      let checked = onOff
      this.conditionCheckedArr[index].ischecked = checked
      if (index === 4) {
        // 直接购买身份 与其他6个条件互斥，选择完当前项，其他6项都不可勾选
        if (checked) {
          this.conditionCheckedArr[4].isDisable = false
          this.conditionCheckedArr[index].isSonDiasble = false
          this.conditionCheckedArr.forEach((item, index) => {
            if (index !== 4) {
              this.conditionCheckedArr[index].isDisable = true
              this.conditionCheckedArr[index].isSonDiasble = true
            }
          })
        } else {
          this.conditionCheckedArr[4].isDisable = true
          this.conditionCheckedArr[index].isSonDiasble = true
          this.conditionCheckedArr.forEach((item, index) => {
            if (index !== 4) {
              this.conditionCheckedArr[index].isDisable = false
            }
          })
        }
      } else {
        // 不勾选当前模块的表单不可操作，勾选后可操作性
        this.conditionCheckedArr[index].isSonDiasble = !checked
      }
    },
    beforeUpload(file) {
      const reader = new FileReader()
      // 把Array Buffer转化为blob 如果是base64不需要
      // 转化为base64
      reader.readAsDataURL(file)
      reader.onload = () => {
        this.form.mark = reader.result
      }
      // 转化为blob
      // reader.readAsArrayBuffer(file)
      return false
    },
    beforeUploadbg(file) {
      const reader = new FileReader()
      // 把Array Buffer转化为blob 如果是base64不需要
      // 转化为base64
      reader.readAsDataURL(file)
      reader.onload = () => {
        this.form.background = reader.result
      }
      // 转化为blob
      // reader.readAsArrayBuffer(file)
      return false
    },
    uploadImg(info, index) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        this.$message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        this.$message.error(`${info.file.name} file upload failed.`);
      }
    },
    submit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if (this.form.standardType == 4) {
            if(!this.data.length) return this.$message.error('请添加条件')
            this.form.applyConfig = JSON.stringify(this.data)
            this.form.levelConditions = []
          } else if (this.form.standardType === 1 || this.form.standardType === 2) {
            let arr = []
            this.conditionCheckedArr.forEach((item, index) => {
              if (item.ischecked) {
                let obj = this.formCondition[index]
                arr.push({
                  conditionType: index + 1,
                  conditionValueJson: JSON.stringify(obj),
                  id: item.id ? item.id : ''
                })
              }
            })
            this.form.levelConditions = arr
            this.form.applyConfig = ''
            if (!arr.length) return this.$message.error('请完善达标条件')
          }else if(this.form.standardType === 3) {
            this.form.levelConditions = []
            this.form.applyConfig = ''
          }

          console.log('提交的数据', this.form)
          this.isloading = true
          distribution.addDistributorLevel(this.form).then(res => {
            this.isloading = false
            if (res.success) {
              let msg = ''
              this.form.id ? msg = '修改成功' : msg = '添加成功'
              this.$message.success(msg)
              this.$emit('updateLevels')
              this.onClearForm()
              this.onClose()
            } else {
              this.$message.error(res.message)
            }
          }).catch(res => {
            this.isloading = false
            this.$message.error(res.message)
          })
        } else {
          return false;
        }
      });
    },
    onClearForm() {
      this.form = {
        id: '',
        title: '',
        description: '',
        mark: '',
        background: '',
        standardType: 1,
        levelConditions: []
      }
      this.conditionCheckedArr = [
        {
          ischecked: false,
          isDisable: false,
          isSonDiasble: true
        }, {
          ischecked: false,
          isfaDisable: false,
          isSonDiasble: true
        }, {
          ischecked: false,
          isfaDisable: false,
          isSonDiasble: true
        }, {
          ischecked: false,
          isfaDisable: false,
          isSonDiasble: true
        }, {
          ischecked: false,
          isfaDisable: false,
          isSonDiasble: true
        }, {
          ischecked: false,
          isfaDisable: false,
          isSonDiasble: true
        }, {
          ischecked: false,
          isfaDisable: false,
          isSonDiasble: true
        }
      ]
      this.formCondition = [
        {
          "typeValue": 1,
          "moneyValue": '',
          "activeValue": 1
        },
        {
          "typeValue": 1,
          "goodsIds": "",
          "activeValue": ''
        },
        { buyNumValue: '' },
        { teamSalesValue: '' },
        {
          "configValue": 1,
          "moneyValue": '',
          "goodsIds": ""
        },
        [{
          "levelValue": '',
          "peopleValue": ''
        }],
        [{
          "levelValue": '',
          "peopleValue": ''
        }]
      ]
      this.$forceUpdate()
    }
  }
}
</script>
